.tarifs{
  padding: 6rem 0;
  position: relative;
  .flexer{
    display: flex;
    justify-content: space-between;
    @include respond-to(small){
      flex-direction: column;
      align-items: center;
    }
    > div:first-child{
      width: 30%;
      img{
        max-width: 100%;
      }
      @include respond-to(small){
        margin-bottom: 3rem;
        width: 100%;
        img{
          display: block;
          margin: 0 auto;
        }
      }
    }
    > div:last-child{
      width: 65%;
      flex-direction: column;
      justify-content: space-between;
      display: flex;
      @include respond-to(small){
        width: 100%;
      }
      .tarif-infos{
        display: flex;
        flex-wrap: wrap;
        @include respond-to(small){
          margin-bottom: 3rem;
        }
        p{
          width: 100%;
          max-width: 700px;
        }
        div{
          display: flex;
          align-items: center;

          h1{
            text-transform: uppercase;
            font-size: 2.4rem;
            padding-right: 2rem;
            border-bottom: 1px $brand-color solid;
            font-family: 'MonserratLight', sans-serif;
            margin: 0;
            padding-bottom: 2rem;
            margin-right: 3rem;
            @include respond-to(small){
              padding-right: 0;
              font-size:2rem;
              margin-right:10px;
            }
          }
          svg{
            margin: 0 1rem;
            @include respond-to(small){
              display: block;
            }
          }
        }
      }
    }
  }
  &.tarif-entreprise{
    padding-bottom: 15rem;
    .flexer{
      > div:first-child {
        width: 40%;
        @include respond-to(small){
          width: 100%;
        }
      }

      > div:last-child {
        width: 55%;
        @include respond-to(small){
          width: 100%;
        }
      }
    }
  }
  &:before{
    content: '';
    display: block;
    width: 130px;
    height: 25px;
    background-image: url(../images/chocolate-icon.svg);
    background-repeat: no-repeat;
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translate(-50%,0);
  }
}