.tarifs-table {
    padding-top : 50px;
    padding-bottom : 100px;
    text-align : center;
    overflow : hidden;
    h6{
        text-transform: uppercase;
        font-size: 2.4rem;
        padding-right: 2rem;
        text-align : center;
        font-family: 'MonserratLight', sans-serif;
        margin: 50px 0 0;
    }
    h6 + .simple-paragraph{
        margin-top : 0;
        margin-bottom :30px;
    }
    .simple-title:after{
        display : none;
    }
    .simple-paragraph{
        position : relative;
        max-width: 700px;
        width : 80%;
        margin : 0 auto;
        text-align : center;
        margin-top : 30px;
        img{
            position : absolute;
            right : 0;
            transform: translate(100%);
        }
    }
    .prices-elements{
        display : flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 1200px;
    }
    .price-element {
        display : flex;
        flex-direction :column;
        border        : 1px solid $brand-color;
        border-radius : 10px;
        padding-top : 15px;
        text-align    : center;
        width: calc(25% - 20px);
        margin : 0 5px 10px;
        p{
            margin : 0;
        }
        .type{
            color: #666;
            text-transform : uppercase;
            font-size : 18px;
        }
        .condition, .notice{
            margin : 0;
            font-size : 11px;
            height : 30px;
        }
        .notice{
            padding-top : 5px;
        }
        .price{
            display : flex;
            align-items: center;
            justify-content: center;
            font-family :arial;
            flex-grow :1;
            font-size : 60px;
            line-height : 1.2;
            padding : 20px 10px;
            background : $brand-color;
            color: white;
            &.small-font{
                font-size :25px;
            }
            sup{
                font-size : 30px;
            }
        }
    }
    @media screen and (max-width: 1000px) {
        .simple-paragraph{
            img{
                display : block;
                position: static;
                transform: none;
                margin : 0 auto;
            }
        }
        .price-element{
            width: calc(50% - 30px);
        }
    }
    @media screen and (max-width: 500px) {
        .price-element{
            width: 100%;
            .price{
                padding : 10px 10px;
                font-size : 30px;
                &.small-font{
                    font-size : 20px;
                }
                sup{
                    font-size : 15px;
                }
            }
        }
    }
}