.brochure{
  padding: 6rem 0;
  .container{
    display: flex;
    div{
      width: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    p{
      width: 70%;
      font-size:1.8rem;
    }
  }
}