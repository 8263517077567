.buttonUp{
  display: block;
  border: 1px $brand-color solid;
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #fff;
  border-radius: 100%;
  cursor: pointer;
  z-index: 999;
  span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 1px;
    height: 20px;
    background-color: $brand-color;
    &:before,&:after{
      content: '';
      display: block;
      width: 1px;
      height: 12px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $brand-color;
      transform-origin: left top;
    }
    &:before{
      transform: rotate(-45deg);
    }
    &:after{
      transform: rotate(45deg);
      transform-origin: right top;
    }
  }
}