.location {
    overflow        : hidden;
    .container {
        display         : flex;
        flex-direction  : row;
        justify-content : space-between;
        flex-wrap       : wrap;
        padding-top     : 50px;
        padding-bottom  : 50px;
        width           : 80%;
        .simple-title {
            width : 100%;
            &:after {
                display : none;
            }
        }
        .infos {
            .address {
                padding : 50px 0;
                p {
                    font-size   : 16px;
                    line-height : 1;
                    margin      : 0 0 10px;
                    &:first-child {
                        color : #666;
                    }
                }
            }
            .near {
                p {
                    margin    : 0;
                    font-size : 11px;
                }
            }
        }
        .map {
            width      : calc(100% - 420px);
            text-align : center;
            img {
                width : 100%;
            }
            .google-map-btn {
                margin-top : 30px;
            }
        }
        @media screen and (max-width : 1100px) {
            flex-direction : column;
            .infos {
                font-size  : 0;
                width      : 100%;
                text-align : center;
                .address, .near, img {
                    text-align     : left;
                    width          : calc(100% / 3 - 20px);
                    display        : inline-block;
                    vertical-align : top;
                }
                .address {
                    padding : 0;
                    margin  : 20px 30px;
                }
                .near {
                    margin-top : 20px;
                }
            }
            .map {
                width : 100%;
            }
        }
        @media screen and (max-width : 500px) {
            flex-direction : column;
            .infos {
                img {
                    width : 100%;
                }
                .address, .near {
                    width  : calc(50% - 15px);
                    margin : 30px 0;
                }
                .address {
                    margin-right : 30px;
                }
            }
        }
    }
}