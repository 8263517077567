.intro-chocolate{
  padding: 10rem 0;

  .big-paragraph{
    margin: 0 auto;
    line-height: 3.4rem;
    margin-bottom: 18px;
    &:nth-child(2){
      margin-bottom:8px;
    }
  }
  .flexer{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    >div:first-child{
      flex-basis: 50%;
      @include respond-to(medium){
        flex-basis: 100%;
      }
    }
    >div >img{
      max-width: 100%;
    }
    @include respond-to(medium){
      justify-content: center;
    }
    .intro-infos{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-basis: 50%;
      @include respond-to(medium){
        flex-basis: 100%;
        max-width: 100%;
        img{
          max-width: 100%;
        }
      }
      p:first-child{
        margin-top: 30px;
      }
      div{
        flex-basis: 60%;
        &:after{
          content: '';
          display: block;
          width: 130px;
          height: 25px;
          background-image: url(../images/chocolate-icon.svg);
          background-repeat: no-repeat;
          margin: 30px auto;
        }
        img{
          padding-bottom: 20px;
          border-bottom: 1px $brand-color solid;
        }
        @include respond-to(medium){
          margin-top: 30px;
          flex-basis: auto;
          width: 100%;
        }
      }
    }
  }
}