// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */


html {
    box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*, *::before, *::after {
    box-sizing: inherit;
}

body {
    font-family: 'MonserratLight', sans-serif;
    font-weight: 300;
    color: $text-color;

}

html,body{
    min-width: 100%;
    font-size: 62.5%;
}

/**
 * Basic styles for links
 */
a {
    color: $link-color;
    text-decoration: none;
}

.icon {
    height: 25px;
    width: 25px;
}


h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
    color: $brand-color;
    hyphens: auto;

    .highlight {
        display: block;
        font-weight: 400;
        hyphens: auto;
    }
}
h1 {
    hyphens: none;
    font-size: 18px;
    .highlight {
        font-size: 40px;
        line-height: 38px;
    }
    .subtitle {
        font-size: 24px;
        line-height: 24px;
    }
    @include respond-to(medium) {
        font-size: 34px;
        .highlight {
            font-size: 54px;
            line-height: 52px;
        } 
    }
}

strong, b {
    font-weight: 600;
}

ul{
    @include resetList();
}

p{
    color: $text-color;
    line-height: 1.8;
}

.big-paragraph{
    font-size: 2.1rem;
    text-align: center;
}

.simple-paragraph{
    font-size: 1.5rem;
    a{
        display: block;
        width: 100%;
    }
}

.simple-title{
    font-size: 5.6rem;
    color: #000;
    font-family: 'hiptonSans', sans-serif;
    display: flex;
    justify-content: center;
    position: relative;
    padding-bottom: 4rem;
    text-align: center;
    &:before{
        content: '';
        display: block;
        width: 60px;
        background-image: url(../images/illu.svg);
        background-size:contain;
        background-position: right top;
        margin-right: 10px;
        background-repeat:no-repeat;
    }
    &:after{
        content: '';
        display: block;
        width: 150px;
        height: 3px;
        background-color: $brand-color;
        left: 50%;
        bottom: 0;
        transform: translate(-50%,0);
        position: absolute;
    }
    @include respond-to(medium){
        font-size:4.8rem;
    }
    @include respond-to(small){
        font-size:3.2rem;
        &:before{
            display: none;
        }
    }

}

.big-title{
    font-size:10rem;
    color: $brand-color;
    font-family: 'hiptonSans', sans-serif;
    justify-content: center;
    padding: 10rem 0;
    position: relative;
    display: flex;
    text-align: center;
    @include respond-to(medium){
        padding: 5rem 0;
        font-size:6rem;
    }
    @include respond-to(small){
        font-size:3.2rem;
    }
    &:before, &:after{
        content: '';
        display: block;
        width: 130px;
        height: 25px;
        background-image: url(../images/chocolate-icon.svg);
        background-repeat: no-repeat;
        position: absolute;
        left: 50%;
    }
    &:before{
        top: 100%;
        transform: translate(-50%,-50%);
    }
    &:after{
        top: 0;
        transform: translate(-50%,50%);
    }
}

button{
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    border: none;
    outline: 0;
}

.parallax-wrapper{
    background-color: #fff;
    position: relative;
    top: 100vh;
    overflow: hidden;
}
.container{
    max-width: 1377px;
    margin: 0 auto;
    @include respond-to(large) {
        max-width: 1100px;
    }
    @include respond-to(medium) {
        max-width: 740px;
    }
    @include respond-to(small) {
        max-width: 90%;
    }
}

.fixed-image{
    width: 100%;
    height: 80vh;
    background-attachment: fixed;
    background-position:  center;
    background-size:cover;
    background-repeat:no-repeat;
    @include respond-to(medium){
        height: 600px;
        background-attachment: initial;
    }
    @include respond-to(small){
        height: 400px;
    }
    &.noisette{
        background-image: url(../images/noisette.jpg);
    }
    &.gourmand{
        background-image: url(../images/gourmand.jpg);
    }
}

.single-paragraph{
    text-transform: uppercase;
    font-size: 2.4rem;
    font-family: "MonserratLight", sans-serif;
    align-items: center;
    position: relative;
    padding-bottom: 3rem;
    text-align: center;

    &:before{
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        background-color: #fff;
        background-color: rgba(0,0,0,.7);
        width: 100px;
        height: 1px;
        transform: translate(-50%,0);
    }
    @include respond-to(small){
        font-size: 2rem;
        line-height: 2.4rem;
    }
}
.shape-bee-empty{
    fill: transparent;
    stroke: $brand-color;
    stroke-width: 2px;
    width: 60px;
    height: 60px;
    @include  respond-to(small){
        display: none;
    }
}

.shape-bee-full{
    fill: $brand-color;
    width: 35px;
    height: 35px;
    @include  respond-to(small){
        display: none;
    }
}

.yellow-paragraph{
    font-size: 2.1rem;
    text-align: center;
    font-family:"MonserratLight",sans-serif;
    color: $brand-color;
    @include respond-to(small){
        font-size: 1.8rem;
        line-height: 2.4rem;
    }
}
.download-btn, .google-map-btn{
    margin : 0 auto 20px;
    text-align : center;
    display: inline-block;
    font-size : 12px;
    color:#666;
    &:before{
        content : '';
        margin : 0 auto 10px;
        display : block;
        height : 70px;
        width : 70px;
        background-position : center;
        background-size: contain;
        background-repeat : no-repeat;
    }

}
.download-btn:before{
    background-image : url(../images/download-btn.png);
}
.google-map-btn:before{
    width : 40px;
    height : 40px;
    background-image : url(../images/google-map-btn.png);
}

hr{
    border: none;
    display : block;
    margin : 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    &:after{
        position : relative;
        content : '';
        margin : 0 auto;
        height : 20px;
        transform: translate(0, -50%);
        width : 120px;
        display : block;
        background-image: url(../images/chocolate-icon.svg);
        background-position : center;
        background-size: contain;
        background-repeat : no-repeat;
    }
}