.contact-horaires{
    display : flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top : 50px;
    align-items: flex-start;
    .contact, .horaires{
        border: 1px solid $brand-color;
        padding : 20px 40px;
        width : 470px;
        h6{
            text-transform: uppercase;
            font-size: 2.4rem;
            padding-right: 2rem;
            font-family: 'MonserratLight', sans-serif;
            margin: 0 0 20px;
        }
        p{
            font-size : 13px;
            margin : 0;
            span{
                color: #666;
            }
            a{
                display: block;
                width: 100%;
            }
        }
    }
    @media screen and (max-width: 1100px){
        .contact, .horaires{
            width : 100%;
            margin-bottom : 30px;
        }
    }
}