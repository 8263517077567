.teacher{
  padding: 10rem 0;
  .simple-title{
    max-width: 900px;
    margin: 0 auto;
    &:before{
      min-width: 60px;
    }
    @include respond-to(medium){
      max-width: none;
      margin: 0;
      &:before{
        min-width: 0;
      }
    }
  }
  .big-paragraph{
    margin-top: 30px;
  }
  .ecole-photos{
    display: flex;
    flex-wrap: wrap;
    padding: 2rem 0;
    li{
      flex-basis: 20%;
      position: relative;
      cursor: pointer;
      button{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background-color: rgba(255,255,255,.9);
        transition: opacity .3s ease-out;
        opacity:0;
        cursor: pointer;
        &:before,&:after{
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          border-radius: 10px;
          background-color: $brand-color;
        }
        &:before{
          height: 3px;
          width: 25px;
        }
        &:after{
          width: 3px;
          height: 25px;
        }
      }
      img{
        display: block;
      }
      &:hover{
        img{
          opacity: .5;
        }

        button{
          opacity: 1;
        }
      }
      @include respond-to(small){
        flex-basis: 50%;
      }
      img{
        max-width: 100%;
      }
    }
  }
}