.personnel{
  padding: 8rem 0;
  .slick-dots{
    display: none !important;
  }
  .simple-title{
    &:before{
      min-width: 60px;
      @include  respond-to(small){
        min-width: 0;
      }
    }
  }
  .entreprise-slider-container{
    max-width: calc(90% - 80px);
    margin: 0 auto;
    position: relative;
    .js-nav{
      button{
        position: absolute;
        top: 0;
        background-color: $brand-color;
        width: 40px;
        height: 40px;
        background-image: url(../images/arrow.svg);
        background-repeat:no-repeat;
        background-size: auto 70%;
        background-position: center;
        transform-origin: center;
        &:before{
          display: none;
        }
      }
      .slick-prev{
        right: 100%;
        left: initial;
        transform: none;
      }
      .slick-next{
        left: 100%;
        transform: rotate(180deg);
      }
    }
  }
  .entreprise-slider-container {
    ul {
      margin: 2rem 0;
      max-width: 100%;
      li {
        display: flex !important;
        justify-content: space-between;

        div {
          width: 31%;
          position: relative;
          button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 50px;
            height: 50px;
            border-radius: 100%;
            background-color: rgba(255, 255, 255, .9);
            transition: opacity .3s ease-out;
            opacity: 0;
            cursor: pointer;
            &:before, &:after {
              content: '';
              display: block;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              border-radius: 10px;
              background-color: $brand-color;
            }
            &:before {
              height: 3px;
              width: 25px;
            }
            &:after {
              width: 3px;
              height: 25px;
            }
          }
          &:hover {
            button {
              opacity: 1;
            }
            img {
              opacity: .5;
            }
          }
        }
        img {
          max-width: 100%;
        }
      }
    }
  }
  .entreprise-show-mobile, .js-nav-mobile{
    display: none;
    @include  respond-to(small){
      display: block;
    }
    li.slick-slide{
      justify-content: center;
      div.launcher{
        width: 90%;
      }
    }
  }
  .entreprise-show-desktop, .js-nav-desktop{
    display: block;
    @include  respond-to(small){
      display: none;
    }
  }
}