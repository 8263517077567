.section-activities{
  overflow: hidden;
  .container{
    position: relative;
    .shape-bee-empty,.shape-bee-full{
      position: absolute;
    }
    .shape-bee-empty.shape-bee-left{
      right: calc(100% + 20px);
      top: 150px;
    }
    .shape-bee-full.shape-bee-left{
      top: 50%;
      right: calc(100% + 10px);
      transform: translate(0,-50%);
    }
    .shape-bee-empty.shape-bee-right{
      top: calc(100% - 150px);
      left: calc(100% + 50px);
    }
    .shape-bee-full.shape-bee-right{
      top: calc(100% - 150px);
      left: calc(100% + 10px);
    }
  }
  .activities{
    display: flex;
    justify-content: space-between;
    padding: 10rem 0;
    flex-wrap: wrap;
    @include respond-to(small){
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    li{

      width: 23%;
      max-width: 300px;
      display: flex;
      flex-direction: column;
      @include respond-to(medium){
        width: 50%;
        margin-bottom: 30px;
      }
      @include respond-to(small){
        width: 100%;
      }
      div:first-child{
        position: relative;
        button{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          width: 50px;
          height: 50px;
          border-radius: 100%;
          background-color: rgba(255,255,255,.9);
          transition: opacity .3s ease-out;
          opacity:0;
          cursor: pointer;
          &:before,&:after{
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            border-radius: 10px;
            background-color: $brand-color;
          }
          &:before{
            height: 3px;
            width: 25px;
          }
          &:after{
            width: 3px;
            height: 25px;
          }
        }
        &:hover{
          img{
            opacity: .5;
          }

          button{
            opacity: 1;
          }
        }
      }
      div:last-child{
        border: 1px $brand-color solid;
        padding: 20px;
        flex-grow: 1;
      };

      img{
        display: block;
        width: calc(100% - 40px);
        margin: 0 auto 20px auto;
        cursor: pointer;
      }

      h1{
        font-size: 2.4rem;
        color: $brand-color;
        font-family: "MonserratLight", sans-serif;
        text-transform: uppercase;
        margin-top: 0;
        hyphens: auto;
      }
      p{
        font-size:1.5rem;
      }
    }
  }
}