.partners{
    overflow : hidden;
    h1:after{
        display : none;
    }
    h6{
        text-transform: uppercase;
        font-size: 2.4rem;
        padding-right: 2rem;
        font-family: 'MonserratLight', sans-serif;
        margin: 0 0 20px;
    }
    .partners-list{
        margin-top : 40px;
        margin-bottom : 50px;
        .partner{
            display: inline-block;
            margin-right : 40px;
            vertical-align : middle;
            margin-bottom : 40px;
        }
    }
}