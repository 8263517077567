.programme-container{
  max-width: 100%;
  overflow: hidden;
  position: relative;
  padding-bottom: 6rem;
  &:before{
    content: '';
    display: block;
    width: 130px;
    height: 25px;
    background-image: url(../images/chocolate-icon.svg);
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,0);
  }
}
.programme{
  padding: 1rem 2rem;
  margin: 3rem 0;
  border: 1px $brand-color solid;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  li{
    flex-basis: 50% ;
    display: flex;
    align-items: center;
    @include  respond-to(medium){
      p{
        font-size:1.6rem;
      }
    }
    @include respond-to(small){
      flex-basis: 100%;
      margin-bottom: 3rem;
      &:last-child{
        margin-bottom: 0;
      }
    }
    p{
      text-align: left;
    }
    span{
      font-size: 5rem;
      font-family: "hiptonSans", sans-serif;
      min-width: 80px;
      height: 80px;
      border: 1px $brand-color solid;
      border-radius: 100%;
      background-size: cover;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      color: #000;
    }
  }
}