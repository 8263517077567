@keyframes fade-in {
  0% {
    transform: translate(0, 100px);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes fade-in-mobile {
  0% {
    transform: translate(-50%, 100px);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, 0);
    opacity: 1
  }
}

.homepage-slider-container{
  position: fixed;
  width: 100%;
  img{
    position: absolute;
    animation: fade-in 1s ease-out forwards;
    transform: translate(0, 100px);
    opacity: 0;
    &.logo-homepage{
      max-width: 20%;
      top: 100px;
      left: 30px;
      @include respond-to(medium){
        max-width: none;
      }
      @include respond-to(small){
        display: none;
      }
    }
    &.citation{
      max-width: 500px;
      bottom: 20px;
      right: 30px;
      animation: fade-in 1s .3s ease-out forwards;
      @include respond-to(medium){
        max-width: none;
      }
      @include respond-to(small){
        animation: fade-in-mobile 1s .3s ease-out forwards;
        max-width: 70%;
        bottom: 130px;
        left: 50%;
        transform: translate(-50%,0);
      }
    }
  }
  .navElements{
    button{
      position: absolute;
      top: 50%;
      background-color: $brand-color;
      width: 40px;
      height: 40px;
      background-image: url(../images/arrow.svg);
      background-repeat:no-repeat;
      background-size: auto 70%;
      background-position: center;
      &:before{
        display: none;
      }
    }
    .slick-prev{
      left: 0;
    }
    .slick-next{
      right: 0;
      transform: translate(0,-50%) rotate(180deg);
    }
  }
}
.homepage-slider{
  li{
    width: 100%;
    height: 100vh;
    &.homepage-slide{
      background-position:  center;
      background-size: cover;
      background-repeat: no-repeat;
    }
    &.homepage-slide-1-fr{
      background-image: url("../images/homepage-header/homepage-halloween-fr.jpg");
    }
    &.homepage-slide-1-nl{
      background-image: url("../images/homepage-header/homepage-halloween-nl.jpg");
    }
    &.homepage-slide-1-en{
      background-image: url("../images/homepage-header/homepage-halloween-en.jpg");
    }
    &.homepage-slide-2{
      background-image: url("../images/homepage-header/homepage-header-1.jpg");
    }
    &.homepage-slide-3{
      background-image: url("../images/homepage-header/homepage-header-2.jpg");
    }
    &.homepage-slide-4{
      background-image: url("../images/homepage-header/homepage-header-3.jpg");
    }

  }
}

