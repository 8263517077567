.visit {
    overflow : hidden;
    .simple-title {
        flex-wrap : wrap;
        position  : relative;
        &:after {
            content          : "\“";
            display          : block;
            width            : 100%;
            text-align       : center;
            font-family      : "Monserrat", sans-serif;
            font-size        : 20rem;
            max-height       : 50px;
            border           : none;
            transform        : initial;
            position         : static;
            height           : auto;
            background-color : transparent;
            @include respond-to(small) {
                font-size : 10rem;
                position  : absolute;
                left      : 0;
                bottom    : 0;
            }
        }
    }
    .bulls-container {
        background-image : url(../images/visit-background.png);
        background-size  : contain;
    }
    .bulls {
        text-align : center;
        padding-top : 50px;
        padding-bottom : 50px;

        li {
            position : relative;
            width: auto;
            height : auto;
            vertical-align : middle;
            display: inline-block;
            margin : -50px 30px 0;

        }
        .text {
            text-align : left;
            z-index : 1;
            background-image : url(../images/svg-sprite/shape-bee.svg);
            background-size  : contain;
            background-repeat : no-repeat;
            max-width: 90%;
            p {
                position : absolute;
                width : 75%;
                font-size : 14px;
                top  : 50%;
                left : 50%;
                transform: translate(-50%, -50%);
                padding : 0;
                margin : 0;
                .author {
                    margin-top :15px;
                    display : block;
                    font-style : italic;
                    text-align : right;
                }
            }
        }
    }
    @media screen and (max-width: 1000px){
        .bulls li {
            margin : -50px 15px 0;
        }
    }
    @media screen and (max-width: 900px){
        .bulls li.img{
            display : none;
        }
        .bulls li{
            &.text{
                margin : 0 auto 15px;
                padding : 30px;
                width : 100%;
                background : white;
                border: 1px solid $brand-color;
                p{
                    width : 100%;
                    position : static;
                    transform : none;
                    left : 0;
                    top : 0;
                }
            }
        }
    }
}