// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

$image-path: "../Assets/images";
$icon-path: "../images/icons";


$brand-color: #b7986a;


$text-color: #B3B3B3;
$link-color: #000;

// Other colors in the color palette (in principle these should not be variables as they are only used once)
// Background hoe kunnen we je helpen  - #ebeef2
// Breadcrumb  -  #ddeaf7

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  'small': 767px,
  'medium': 1100px,
  'large': 1460px,
  'xl': '1600px'
)

