// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Managing Responsive Breakpoints
/// https://www.sitepoint.com/managing-responsive-breakpoints-sass/
// See Breakpoints in _variables.scss
@mixin respond-to($breakpoint) {
    // Retrieves the value from the key
    $value: map-get($breakpoints, $breakpoint);

    // If the key exists in the map
    @if $value != null {
    // Prints a media query based on the value
        @media (max-width: $value) {
            @content;
        }
    }
    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
    }
}

@mixin resetList() {
    margin: 0 auto;
    padding: 0;
    list-style: none;
    li{
        margin: 0;
        padding: 0;
        list-style-type: none;
        &::before {
            content: none; // Reset the default list-style applied in base.scss
        }
    }
}
// add this mixin inside a before or after
@mixin iconCss($iconName, $width, $height) {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: $width;
    height: $height;
    background: url('#{$icon-path}/#{$iconName}.svg') center center no-repeat;
    background-size: contain;
    margin-right: 7px;
}
