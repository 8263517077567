@keyframes flash {
  from, 50%, to {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

.section-degustation{
  overflow: hidden;
  .big-title{
    //animation: flash 2s linear infinite ;
  }
}
.degustation{
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
  padding: 10rem 0;
  flex-wrap: wrap;
  @include respond-to(small){
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  li{
    width: 30%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    @include respond-to(medium){
      width: 50%;
      margin-bottom: 30px;
    }
    @include respond-to(small){
      width: 100%;
    }
    div:first-child{
      position: relative;
      button{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background-color: rgba(255,255,255,.9);
        transition: opacity .3s ease-out;
        opacity:0;
        cursor: pointer;
        &:before,&:after{
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          border-radius: 10px;
          background-color: $brand-color;
        }
        &:before{
          height: 3px;
          width: 25px;
        }
        &:after{
          width: 3px;
          height: 25px;
        }
      }
      &:hover{
        img{
          opacity: .5;
        }

        button{
          opacity: 1;
        }
      }
    }
    .degustation-infos{
      border: 1px $brand-color solid;
      padding: 20px;
      flex-grow: 1;
    }

    .degustation-icons{
      display: flex;
      margin-top: 15px;
      align-items: center;
      svg:first-child{
        margin-right:15px;
      }
    }

    .degustation-icons-1{
      justify-content: flex-end;
    }

    .degustation-icons-2{
      justify-content: center;
    }

    .degustation-icons-3{
      justify-content: flex-start;
      svg:last-child{
        width: 35px;
        height: 35px;
      }
    }

    img{
      display: block;
      margin: 0 auto 20px auto;
      cursor: pointer;
      width: 100%;
    }

    h1{
      font-size: 2.4rem;
      color: $brand-color;
      font-family: "MonserratLight", sans-serif;
      text-transform: uppercase;
      margin-top: 0;
    }
    p{
      font-size:1.5rem;
    }
  }
}