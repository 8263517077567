// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

header{
  position: fixed;
  width: 100%;
  z-index: 10;

  &.open{
    height: 100vh;
    &:before{
      content: '';
      display: block;
      position: absolute;
      top: 75px;
      background-color: #fff;
      opacity: .4;
      height: calc(100% - 75px);
      width: 100%;
    }
  }
  @include respond-to(small){
    overflow: hidden;
  }
}
.header {
  background-color: $brand-color;
  padding: 0;


  h1{
    margin: 0;
    a{
      display: block;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
  .main-header{
    display: flex;
    justify-content: space-between;
    @include respond-to(small){
      overflow: hidden;
    }
    nav{
      display: flex;
     > ul{
        @include resetList();
        display: flex;
        li {
          display: flex;
          a {
            display: flex;
            padding: 0 12px;
            transition : background 0.3s ease-out;
            align-items: center;
            cursor: pointer;
            font-size: 1.4rem;
            text-transform: uppercase;
            &:hover, &.active {
              background-color: #fff;
            }
          }
          &.language{
            position: relative;
            ul{
              visibility: hidden;
              position: absolute;
              top:100%;
              left:0;
              width:100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              border: 1px $brand-color solid;
              border-bottom: none;
              transition: visibility 0.3s ease-out;
              li{
                a{
                  padding: 20px 0;
                  width: 100%;
                  justify-content: center;
                  border-bottom: 1px $brand-color solid;
                  background-color: #fff;
                }
              }
            }
            &:hover ul {
              visibility: visible;
            }
          }
        }
      }
      @include  respond-to(small){

        position: absolute;
        left: 100%;
        top: 75px;
        width: 100%;
        background: $brand-color;
        transition: left 0.3s ease-out;
        > ul{
          flex-direction: column;
          align-items: center;
          width: 100%;
          li {
            width: 100%;
            &:first-child a{
              border-top: 1px #fff solid;
            }
            a{
              padding: 20px 0;
              width: 100%;
              border-bottom: 1px #fff solid;
              justify-content: center;
            }
            &.language{
              > a:first-child{
                flex-basis: 33.3333333%;
              }
              ul{
                position: static;
                display: flex;
                visibility: visible;
                flex-direction: row;
                flex-basis: 66.66666%;
                border: none;
                li a {
                  background: $brand-color;
                  border: none;
                  border-bottom: 1px #fff solid;

                }
                li:first-child a {
                  border-left: 1px #fff solid;
                  border-right: 1px #fff solid;
                }
              }
            }
          }
        }
        &.open{
          left : 0;
        }
      }
    }
  }

  .burgerContainer{
    align-items: center;
    display: none;
    @include  respond-to(small){
      display: flex;
    }
  }
  #nav-icon{
    width: 30px;
    height: 25px;
    position: relative;
  }
  #nav-icon span {
    display: block;
    position: absolute;
    height: 2px;
    width: 50%;
    background: #000;
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;

  }

  #nav-icon span:nth-child(even) {
    left: 50%;
    border-radius: 0 9px 9px 0 ;

  }

  #nav-icon span:nth-child(odd) {
    left:0px;
    border-radius: 9px 0 0  9px;

  }

  #nav-icon span:nth-child(1), #nav-icon span:nth-child(2) {
    top: 0px;
  }

  #nav-icon span:nth-child(3), #nav-icon span:nth-child(4) {
    top: 50%;
    transform: translate(0,-50%);
  }

  #nav-icon span:nth-child(5), #nav-icon span:nth-child(6) {
    bottom: 0;
  }

  #nav-icon.open span:nth-child(1) {
    transform: rotate(45deg);
    transform-origin: left bottom;
    left: calc(12.5% + 1px);
  }

  #nav-icon.open span:nth-child(2) {
    transform: rotate(-45deg);
    transform-origin: right bottom;
    right: calc(12.5% + 1px);
    left: inherit;
  }

  #nav-icon.open span:nth-child(3) {
    left: -50%;
    opacity: 0;
  }

  #nav-icon.open span:nth-child(4) {
    left: 100%;
    opacity: 0;
  }

  #nav-icon.open span:nth-child(5) {
    transform: rotate(-45deg);
    transform-origin: left bottom;
    left: calc(12.5% + 1px);
    bottom: 2px;
  }

  #nav-icon.open span:nth-child(6) {
    transform: rotate(45deg);
    transform-origin: right bottom;
    left: inherit ;
    right: calc(12.5% + 1px);
    bottom: 2px;
  }
}

@keyframes fade-in {
  0%{
    height: auto;
  }

}