.spacebar{
  width: 100%;
  background-color: $brand-color;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 35px;
  h1{
    margin : 3px 0;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    font-family: "MonserratLight", sans-serif;
    font-size: 2.1rem;
  }
}