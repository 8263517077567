.modal-container{
  position: fixed;
  top: 75px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 75px);
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: -1;
  opacity:0;
  @include respond-to(small){
    display: none;
  }
  &.open{
    z-index: 10;
    opacity:1;
  }
  >div {
    position: relative;
    z-index:2;
    width: 650px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .slider-for{
      width: 100%;
      &:before{
        content: '';
        display: block;
        width: calc(100% + 50px);
        height: calc(100% + 50px);
        background: #000;
        border: 1px #000 solid;
        border-radius: 5px;
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
      li{
        position: relative;
        height: 850px;
        overflow: hidden;
        display: none;
        @include respond-to(xl){
          height: 580px;
        }
        @include respond-to(large){
          height: 420px;
        }
        &.show{
          display: block;
        }
      }
      img{
        min-width: 100%;
        min-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
  }
  .modal{
    background-color: rgba(255,255,255,.7);
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    cursor: pointer;
    &:before,&:after{
      content: '';
      display: block;
      width: 20px;
      height: 1px;
      position: absolute;
      top: 30px;
      right: 30px;
      background-color: #000;
    }
    &:before{
      transform: rotate(45deg);
    }
    &:after{
      transform: rotate(-45deg);
    }
  }
}