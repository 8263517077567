// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------
@font-face {
  font-family: 'hiptonSans';
  src: url(../fonts/HiptonSans-Regular.otf);
}

@font-face {
  font-family: 'hiptonSansLight';
  src: url(../fonts/HiptonSans-Line.otf);
}


@font-face {
  font-family: 'Monserrat';
  src: url(../fonts/Montserrat-Regular.otf);
}

@font-face {
  font-family: 'MonserratLight';
  src: url(../fonts/Montserrat-Light.otf);
}

