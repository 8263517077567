.actualites-list {
    overflow    : hidden;
    padding-top : 50px;
    padding-bottom: 50px;

    .actus-articles {
        padding-top : 30px;
        article {
            margin-top     : 30px;
            min-height     : 300px;
            background     : white;
            text-align : justify;
            font-size : 20px;
            line-height : 1.8;
            padding-bottom : 50px;
            position       : relative;
            .content{
                width : calc(100% - 430Px);
                display : block;
                margin : 0 0 0 auto;
            }
            img {
                width: 400px;
                display : block;
                float : left;
                margin-left: -430px;

            }
            h1 {
                text-align : center;
                font-size : 40px;
            }
            @media screen and (max-width : 1100px) {
                font-size : 16px;
                line-height : 1.6;
                .content{
                    width : calc(100% - 230Px);
                }
                img {
                    width: 200px;
                    margin-left: -230px;

                }
                h1 {
                    font-size : 30px;
                }
            }
            @media screen and (max-width : 550px) {
                .content{
                    width : 100%;
                }
                img {
                    width: 100%;
                    margin: 15px auto;
                }
            }
        }
    }
}